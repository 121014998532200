<!--<template>-->
<!--  <div>-->
<!--    <div class="tabbar">-->
<!--      <van-tabbar v-model="active" :active-color="variables.theme" :fixed="false" route>-->
<!--        <van-tabbar-item :to="{name: 'Home'}" icon="wap-home">首页</van-tabbar-item>-->
<!--        <van-tabbar-item :to="{name: 'Category'}" icon="bars">分类</van-tabbar-item>-->
<!--        <van-tabbar-item v-show="isLoggedIn" :to="{name: 'Center'}" icon="cluster-o">控制台</van-tabbar-item>-->
<!--        <van-tabbar-item :to="{name: 'Cart'}" icon="shopping-cart">购物车</van-tabbar-item>-->
<!--        <van-tabbar-item :to="{name: 'User'}" icon="manager">我的</van-tabbar-item>-->
<!--      </van-tabbar>-->
<!--    </div>-->
<!--    <div class="tabbar&#45;&#45;placeholder" style="width:100%;height:50px" />-->
<!--  </div>-->
<!--</template>-->

<template>
  <div>
    <div  class="tabbar">
      <van-tabbar v-model="active" :active-color="variables.theme" :fixed="false" route>
        <van-tabbar-item :to="{name: 'Home'}" icon="wap-home">首页</van-tabbar-item>
        <van-tabbar-item :to="{name: 'Category'}" icon="bars">分类</van-tabbar-item>
        <van-tabbar-item v-show="isLoggedIn" :to="{name: 'Center'}" icon="cluster-o">控制台
        </van-tabbar-item>
        <van-tabbar-item :to="{name: 'Cart'}" icon="shopping-cart">购物车</van-tabbar-item>
        <van-tabbar-item :to="{name: 'User'}" icon="manager">我的</van-tabbar-item>
      </van-tabbar>
    </div>
    <div class="tabbar--placeholder" style="width:100%;height:50px" />
  </div>
</template>

<script>
// import variables from '@/styles/variables.scss'
// import { checkLoginStatus } from '@/api/user'

export default {
  data() {
    return {
      classArray: ['', '', '', '', ''], // 定义一个包含5个空字符串的数组
      active: 0,
      isLoggedIn: false, // 添加一个新的data属性，用于保存登录状态
      variables: {
        theme: '#79623e' // 主题颜色，根据你的实际情况设置
      }
    }
  },
  // created() {
  //   this.onChange()
  // },
  methods: {
    onChange() {
      for (let i = 0; i < this.classArray.length; i++) {
        this.classArray[i] = ''
      }
      this.classArray[this.active] = 'custom-icon'
    },
    checkLoginStatus() {
      // 在这里执行实际的登录状态检查逻辑
      const cookies = document.cookie.split(';')
      let token = ''
      let isLoggedIn = false

      cookies.forEach(cookie => {
        const [name, value] = cookie.trim().split('=')
        if (name === 'token') {
          token = decodeURIComponent(value)
          isLoggedIn = !!token // 判断 token 是否存在，如果存在则认为用户已登录
        }
      })

      this.isLoggedIn = isLoggedIn // 更新组件的登录状态
    }
  },
  mounted() {
    this.checkLoginStatus() // 调用方法检查登录状态
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  z-index: 999;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  //border-top: 2px solid #f5f5f5;
  border-top: none;
}
//[class*='van-hairline']::after {
//  position: absolute;
//  box-sizing: border-box;
//  content: ''; /* 保留content为空字符串 */
//  /* 其他样式属性 */
//  bottom: 0;
//  left: 0;
//  border-top: none; /* 移除border-top属性 */
//}




//.custom-icon {
//  /* 添加抖音故障风格的样式，例如使用CSS动画效果、颜色变化等 */
//  //color: #fff;
//  position: relative;
//  width: 100px; /* 设置相等的宽度和高度 */
//  height: 100px;
//  margin-top: -15px;
//  text-shadow: 2px 2px #fd1755,-2px -1px #2bf2ec, 30px 20px rgba(255, 255, 255, 0.03);
//  animation: shake 0.5s;
//  animation-fill-mode: forwards; /* 动画停留在最后一帧 */
//  border-radius: 50% 50% 0 0; /* 将图标设置为顶部尖锐的圆形 */
//  overflow: visible; /* 允许超出状态栏 */
//}
@keyframes shake {
  0% {
    transform: translateX(0); /* 初始位置为0 */
  }
  50% {
    transform: translateX(-5px); /* 向左移动5像素 */
  }
  100% {
    transform: translateX(5px); /* 向右移动5像素 */
  }
}
</style>
